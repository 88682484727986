import Vue from "vue";
import Vuex from "vuex";
import {
  getColumnData,
  getZipCode,
  getCarouselData,
  getMetaData,
  getBrandData,
} from "@/api/page_data.js";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    main_dialog: {
      status: false, //系統訊息顯示狀態
      content: "", //系統訊息內容
    },
    image_loaded: false, //頁面圖片讀取狀態
    loading: 0, //Loading狀態，大於0則為讀取中，等於0則為讀取完畢
    shop_cart_open: false, //購物車抽屜打開狀態
    shop_cart_dialog: false, //購物車訊息打開狀態
    search_dialog: false, //搜尋視窗打開狀態
    // api資料
    common_column_data: null, //通用欄位資料
    carousel_data: null, //首頁輪播資料
    page_meta_data: null, //seo meta資料
    zipcode_data: null, //縣市郵遞區號資料
    brand_data: null, //服務項目資料
  },
  getters: {
    // 取得資料讀取狀況
    all_data_loaded(state) {
      if (
        state.common_column_data != null &&
        state.carousel_data != null &&
        state.zipcode_data != null &&
        state.page_meta_data != null &&
        state.brand_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 取得header公告
    announcement_list: (state) => {
      let list = [];
      for (let i = 0; i < 10; i++) {
        const content = state.common_column_data.filter(
          (item) => item.ColumnKey == `event_announcement_${i + 1}`
        )[0].Content;
        const link = state.common_column_data.filter(
          (item) => item.ColumnKey == `event_announcement_link_${i + 1}`
        )[0].Content;
        content != ""
          ? list.push({
              content: content,
              link: link,
            })
          : "";
      }
      return list;
    },
    // 取得指定的Meta資料
    page_meta_data: (state) => (page_title) => {
      return state.page_meta_data.filter(
        (item) => item.PageTitle == page_title
      )[0];
    },
    // 取得申請流程資料
    flow_list: (state) => {
      let list = [];
      for (let i = 0; i < 3; i++) {
        const title = state.common_column_data.filter(
          (item) => item.ColumnKey == `flow_title_${i + 1}`
        )[0].Content;
        const content = state.common_column_data.filter(
          (item) => item.ColumnKey == `flow_content_${i + 1}`
        )[0].Content;
        const image = state.common_column_data.filter(
          (item) => item.ColumnKey == `flow_image_${i + 1}`
        )[0].Content;
        content != ""
          ? list.push({
              content: content,
              title: title,
              image: image,
            })
          : "";
      }
      return list;
    },
    // 取得服務項目資料
    features_list: (state) => {
      let list = [];
      for (let i = 0; i < 3; i++) {
        const title = state.common_column_data.filter(
          (item) => item.ColumnKey == `features_${i + 1}_title`
        )[0].Content;
        const image = state.common_column_data.filter(
          (item) => item.ColumnKey == `features_${i + 1}_image`
        )[0].Content;
        list.push({
          title: title,
          image: image,
        });
      }
      return list;
    },
    // 取得詐騙類型資料
    scam_list: (state) => {
      let list = [];
      for (let i = 0; i < 6; i++) {
        const title = state.common_column_data.filter(
          (item) => item.ColumnKey == `scam_type_${i + 1}_title`
        )[0].Content;
        const content = state.common_column_data.filter(
          (item) => item.ColumnKey == `scam_type_${i + 1}_content`
        )[0].Content;
        const image = state.common_column_data.filter(
          (item) => item.ColumnKey == `scam_type_${i + 1}_image`
        )[0].Content;
        list.push({
          title: title,
          content: content,
          image: image,
        });
      }
      return list;
    },
  },
  mutations: {
    SetShopCartDialog(state, action) {
      state.shop_cart_dialog = action;
    },
    // 設定購物車打開狀態
    SetShopCartStatus(state, action) {
      state.shop_cart_open = action;
    },
    // 設定頁面圖片讀取狀態
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    // 設定Loading狀態
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : "";
      }
    },
    // 設定Dialog狀態與內容文字
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    // 設定State資料
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData(state) {
      state.common_column_data == null ? state.dispatch("getColumnData") : "";
      state.zipcode_data == null ? state.dispatch("getZipCode") : "";
      state.carousel_data == null ? state.dispatch("getCarouselData") : "";
      state.page_meta_data == null ? state.dispatch("getMetaData") : "";
      state.brand_data == null ? state.dispatch("getBrandData") : "";
    },
    // 取得服務項目資料
    getBrandData(state) {
      getBrandData().then((res) => {
        state.commit("SetStateData", {
          key: "brand_data",
          val: res.data,
        });
      });
    },
    // 取得meta資料
    getMetaData(state) {
      getMetaData().then((res) => {
        state.commit("SetStateData", {
          key: "page_meta_data",
          val: res.data,
        });
      });
    },
    // 取得通用欄位資料
    getColumnData(state) {
      getColumnData().then((res) => {
        state.commit("SetStateData", {
          key: "common_column_data",
          val: res.data,
        });
      });
    },
    // 取得縣市郵遞區號資料
    getZipCode(state) {
      getZipCode().then((res) => {
        state.commit("SetStateData", {
          key: "zipcode_data",
          val: res.data,
        });
      });
    },
    // 取得首頁大圖輪播資料
    getCarouselData(state) {
      getCarouselData().then((res) => {
        state.commit("SetStateData", {
          key: "carousel_data",
          val: res.data,
        });
      });
    },
  },
  modules: {},
});

export default store;
