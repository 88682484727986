<template>
  <div id="app" class="relative z-10 flex flex-col w-full min-h-screen">
    <MainLoading />

    <template v-if="all_data_loaded">
      <!-- <MainHeader
        :menu_status="menu_status"
        @toggle-menu="menu_status = !menu_status"
      />
      <MainMenu
        :menu_status="menu_status"
        @toggle-menu="menu_status = !menu_status"
      /> -->
      <MainDialog />

      <!-- Cookie與廣告資料搜集同意視窗 -->
      <!-- <CookieConsentDialog /> -->

      <div class="relative z-10 flex flex-col flex-1 min-h-screen">
        <router-view
          class="relative flex-1 z-1"
          @set-meta="SetMetaData"
          @load-image="LoadImage"
        />
      </div>

      <!-- <ContactFooter /> -->
      <!-- <MainFooter /> -->
    </template>
  </div>
</template>

<script>
import { ImageLoader } from "@/gsap/ImageLoader";
import { GetMetaData } from "@/common/meta";
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
// import ContactFooter from "@/components/ContactFooter.vue";
// import MainFooter from "@/components/MainFooter.vue";
// import MainHeader from "@/components/MainHeader.vue";
// import MainMenu from "@/components/MainMenu.vue";
// import CookieConsentDialog from "@/comp   onents/CookieConsentDialog.vue";
export default {
  name: "App",
  components: {
    MainDialog,
    MainLoading,
    // ContactFooter,
    // MainFooter,
    // MainHeader,
    // MainMenu,
    // CookieConsentDialog,
  },
  data() {
    return {
      image_loader: null,
      menu_status: false,
      meta_data: null,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    SetMetaData(title, content, image) {
      this.meta_data = GetMetaData(title, content, image);
    },
  },
  watch: {
    all_data_loaded() {
      if (this.all_data_loaded) {
        this.$store.dispatch("shopcart_module/GetShopCart");
        this.$store.commit("SetLoading", -1);
      }
    },
    $route() {
      console.log(this.$route);
      if (this.$route.hash != "") {
        // 滾到到錨點並距離頂部200px
        this.$nextTick(() => {
          const target = document.querySelector(this.$route.hash);
          if (target) {
            window.scrollTo({
              top: target.offsetTop,
              behavior: "smooth",
            });
          }
        });
      }
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  mounted() {
    // this.$store.commit("SetLoading", 1);
    this.image_loader = new ImageLoader();
    window.addEventListener("resize", () => {
      this.menu_status = false;
    });
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch("getAllData") : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style src="@/assets/css/output.css"></style>
