import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "關於我們",
  //   component: () => import("../views/AboutView.vue"),
  // },
  // {
  //   path: "/question",
  //   name: "常見問題",
  //   component: () => import("../views/QuestionView.vue"),
  // },
  // {
  //   path: "/glossary",
  //   name: "金融名詞解釋",
  //   component: () => import("../views/GlossaryView.vue"),
  // },
  // {
  //   path: "/debt/:id",
  //   name: "聯徵查詢結果",
  //   component: () => import("../views/DebtView.vue"),
  // },
  // {
  //   path: "/apply_online/form",
  //   name: "貸款申請",
  //   component: () => import("../views/Application.vue"),
  // },
  {
    path: "/apply_online/finish/:id",
    name: "申請完成",
    component: () => import("../views/ApplyFinish.vue"),
  },
  // {
  //   path: "/apply_online/verify",
  //   name: "保證人驗證",
  //   component: () => import("../views/GuarantorVerify.vue"),
  // },
  {
    path: "/apply_online/guarantor/:id",
    name: "保證人簽署",
    component: () => import("../views/GuarantorForm.vue"),
  },
  {
    path: "/apply_online/guarantor_finish",
    name: "保證人完成簽署",
    component: () => import("../views/GuarantorFinish.vue"),
  },
  {
    path: "/contract/:id",
    name: "合約簽署",
    component: () => import("../views/ContractDownload.vue"),
  },
  {
    path: "/void_contract/:id",
    name: "作廢合約查詢",
    component: () => import("../views/VoidContract.vue"),
  },
  // {
  //   path: "/terms/:id",
  //   name: "terms_page",
  //   component: () => import("../views/TermsView.vue"),
  // },
  {
    path: "/error_page",
    name: "錯誤頁面",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 換頁滾動至最上面
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (hasQueryParams(from) && from.query.order_memo) {
    if (to.query && to.query.order_memo) {
      next();
    } else {
      let new_query = null;
      new_query = to.query ? to.query : from.query;
      if (!to.order_memo) {
        new_query.order_memo = from.query.order_memo;
      }
      next({ name: to.name, query: new_query, params: to.params });
    }
  } else {
    next();
  }
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
